<!--
 * @Author: pengyu
 * @Date: 2021-07-05 09:53:45
 * @LastEditors: pengyu
 * @LastEditTime: 2021-07-05 09:53:45
 * @Description: file content
-->
<template>
  <span>{{startTime | formatDuring}}</span>
</template>

<script>
/* eslint-disable radix */
export default {
  name: "CountDown",
  props: {
    time: {
      type: [String, Number]
    }
  },
  data() {
    return {
      timer: null,
      startTime: this.time
    };
  },
  watch: {
    time: {
      immediate: true,
      handler(newVal) {
        this.startTime = newVal;
        this.start();
      }
    }
  },
  filters: {
    formatDuring(mss) {
      const padLeftZero = (num) => (num < 10 ? `0${ num}` : num);
      // const days = parseInt(mss / (1000 * 60 * 60 * 24));

      const hours = parseInt((mss / (1000 * 60 * 60)));

      const minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));

      const seconds = (mss % (1000 * 60)) / 1000;

      return `${ padLeftZero(hours) } : ${ padLeftZero(minutes) } : ${ padLeftZero(seconds) }`;
    },
    formatTime(d, fmt) {
      const date = new Date(d);
      if (/(y+)/.test(fmt)) {
          fmt = fmt.replace(RegExp.$1, (`${date.getFullYear() }`).substr(4 - RegExp.$1.length));
      }
      const o = {
          "M+": date.getMonth() + 1,
          "d+": date.getDate(),
          "h+": date.getHours(),
          "m+": date.getMinutes(),
          "s+": date.getSeconds()
      };
      // eslint-disable-next-line no-restricted-syntax
      for (const k in o) {
        if (new RegExp(`(${k })`).test(fmt)) {
          const str = `${o[k] }`;
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : (`00${ str}`).substr(str.length));
        }
      }
      return fmt;
    }
  },
  methods: {
    start() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
        this.startTime -= 1000;
        if (this.startTime <= 0) {
          this.$emit("ending");
          this.stop();
        }
      }, 1000);
    },
    stop() {
      clearInterval(this.timer);
    }
  }
};
</script>

<style>

</style>
