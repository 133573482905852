/*
 * @Author: pengyu
 * @Date: 2021-07-05 09:33:19
 * @LastEditors: pengyu
 * @LastEditTime: 2021-07-05 09:33:28
 * @Description: file content
 */
export const bankList = [
  {
      value: "100",
      name: "邮储银行"
  },
  {
      value: "102",
      name: "工商银行"
  },
  {
      value: "103",
      name: "农业银行"
  },
  {
      value: "104",
      name: "中国银行"
  },
  {
      value: "105",
      name: "建设银行"
  },
  {
      value: "201",
      name: "国家开发银行"
  },
  {
      value: "202",
      name: "中国农业发展银行"
  },
  {
      value: "301",
      name: "交通银行"
  },
  {
      value: "302",
      name: "中信银行"
  },
  {
      value: "303",
      name: "光大银行"
  },
  {
      value: "304",
      name: "华夏银行"
  },
  {
      value: "305",
      name: "民生银行"
  },
  {
      value: "306",
      name: "广发银行"
  },
  {
      value: "307",
      name: "平安银行"
  },
  {
      value: "308",
      name: "招商银行"
  },
  {
      value: "309",
      name: "兴业银行"
  },
  {
      value: "310",
      name: "浦发银行"
  },
  {
      value: "311",
      name: "恒丰银行"
  },
  {
      value: "313",
      name: "华融湘江银行（原株洲市商业银行、"
  },
  {
      value: "湘潭市商业银行、岳阳市商业银行、衡阳市商业银行）"
  },
  {
      value: "316",
      name: "浙商银行"
  },
  {
      value: "317",
      name: "渤海银行"
  },
  {
      value: "321",
      name: "重庆三峡银行"
  },
  {
      value: "401",
      name: "上海银行"
  },
  {
      value: "402",
      name: "厦门银行"
  },
  {
      value: "403",
      name: "北京银行"
  },
  {
      value: "404",
      name: "烟台银行"
  },
  {
      value: "405",
      name: "福建海峡银行"
  },
  {
      value: "408",
      name: "宁波银行"
  },
  {
      value: "409",
      name: "齐鲁银行"
  },
  {
      value: "410",
      name: "福州银行"
  },
  {
      value: "411",
      name: "焦作市商业银行"
  },
  {
      value: "412",
      name: "温州银行"
  },
  {
      value: "413",
      name: "广州银行"
  },
  {
      value: "414",
      name: "汉口银行"
  },
  {
      value: "银行编码"
  },
  {
      value: "415",
      name: "甘肃银行"
  },
  {
      value: "417",
      name: "盛京银行"
  },
  {
      value: "418",
      name: "洛阳银行"
  },
  {
      value: "419",
      name: "辽阳银行"
  },
  {
      value: "420",
      name: "大连银行"
  },
  {
      value: "421",
      name: "苏州银行"
  },
  {
      value: "422",
      name: "河北银行"
  },
  {
      value: "423",
      name: "杭州银行"
  },
  {
      value: "424",
      name: "南京银行"
  },
  {
      value: "425",
      name: "东莞银行"
  },
  {
      value: "426",
      name: "金华银行"
  },
  {
      value: "427",
      name: "乌鲁木齐市商业银行"
  },
  {
      value: "428",
      name: "绍兴银行"
  },
  {
      value: "429",
      name: "成都银行"
  },
  {
      value: "430",
      name: "抚顺银行"
  },
  {
      value: "431",
      name: "临商银行"
  },
  {
      value: "432",
      name: "湖北银行"
  },
  {
      value: "433",
      name: "葫芦岛银行"
  },
  {
      value: "434",
      name: "天津银行"
  },
  {
      value: "435",
      name: "郑州银行"
  },
  {
      value: "436",
      name: "宁夏银行"
  },
  {
      value: "437",
      name: "珠海华润银行"
  },
  {
      value: "438",
      name: "齐商银行"
  },
  {
      value: "439",
      name: "锦州银行"
  },
  {
      value: "440",
      name: "徽商银行"
  },
  {
      value: "441",
      name: "重庆银行"
  },
  {
      value: "442",
      name: "哈尔滨银行"
  },
  {
      value: "443",
      name: "贵阳银行（贵阳市商业银行）"
  },
  {
      value: "444",
      name: "西安银行"
  },
  {
      value: "446",
      name: "丹东银行"
  },
  {
      value: "447",
      name: "兰州银行"
  },
  {
      value: "448",
      name: "江西银行（南昌银行）"
  },
  {
      value: "449",
      name: "晋商银行"
  },
  {
      value: "450",
      name: "青岛银行"
  },
  {
      value: "451",
      name: "吉林银行"
  },
  {
      value: "454",
      name: "九江银行"
  },
  {
      value: "455",
      name: "日照银行"
  },
  {
      value: "456",
      name: "鞍山银行"
  },
  {
      value: "457",
      name: "秦皇岛市商业银行"
  },
  {
      value: "458",
      name: "青海银行"
  },
  {
      value: "459",
      name: "台州银行"
  },
  {
      value: "461",
      name: "长沙银行"
  },
  {
      value: "462",
      name: "潍坊银行"
  },
  {
      value: "463",
      name: "赣州银行"
  },
  {
      value: "464",
      name: "泉州银行"
  },
  {
      value: "465",
      name: "营口银行"
  },
  {
      value: "466",
      name: "富滇银行"
  },
  {
      value: "467",
      name: "阜新银行"
  },
  {
      value: "470",
      name: "嘉兴银行"
  },
  {
      value: "472",
      name: "廊坊银行"
  },
  {
      value: "473",
      name: "浙江泰隆商业银行"
  },
  {
      value: "474",
      name: "内蒙古银行"
  },
  {
      value: "475",
      name: "湖州银行"
  },
  {
      value: "478",
      name: "广西北部湾银行"
  },
  {
      value: "479",
      name: "蒙商银行（包商银行）"
  },
  {
      value: "481",
      name: "威海市商业银行"
  },
  {
      value: "483",
      name: "攀枝花市商业银行"
  },
  {
      value: "485",
      name: "绵阳市商业银行"
  },
  {
      value: "486",
      name: "泸州市商业银行"
  },
  {
      value: "487",
      name: "大同市商业银行"
  },
  {
      value: "488",
      name: "三门峡银行"
  },
  {
      value: "489",
      name: "广东南粤银行"
  },
  {
      value: "490",
      name: "张家口市商业银行"
  },
  {
      value: "491",
      name: "桂林银行"
  },
  {
      value: "493",
      name: "江苏长江商业银行"
  },
  {
      value: "495",
      name: "柳州银行"
  },
  {
      value: "496",
      name: "南充市商业银行"
  },
  {
      value: "497",
      name: "莱商银行"
  },
  {
      value: "498",
      name: "德阳银行"
  },
  {
      value: "499",
      name: "新疆银行"
  },
  {
      value: "500",
      name: "六盘水市商业银行"
  },
  {
      value: "502",
      name: "曲靖市商业银行"
  },
  {
      value: "701",
      name: "昆仑银行"
  },
  {
      value: "1401",
      name: "上海农村商业银行"
  },
  {
      value: "1402",
      name: "昆山农村商业银行"
  },
  {
      value: "1403",
      name: "江苏常熟农村商业银行"
  },
  {
      value: "1404",
      name: "深圳农村商业银行"
  },
  {
      value: "1405",
      name: "广州农村商业银行"
  },
  {
      value: "1406",
      name: "浙江萧山农村商业银行（浙江萧山农村合作银行）"
  },
  {
      value: "1407",
      name: "广东南海农村商业银行"
  },
  {
      value: "1408",
      name: "佛山顺德农村商业银行"
  },
  {
      value: "1409",
      name: "昆明市农村信用合作社联合社"
  },
  {
      value: "1410",
      name: "湖北省农村信用社联合社"
  },
  {
      value: "1411",
      name: "徐州市市郊农村信用合作社联合社"
  },
  {
      value: "1412",
      name: "江苏江阴农村商业银行"
  },
  {
      value: "1413",
      name: "重庆农村商业银行"
  },
  {
      value: "1414",
      name: "山东省农村信用社联合社"
  },
  {
      value: "1415",
      name: "东莞农村商业银行"
  },
  {
      value: "1416",
      name: "张家港农村商业银行"
  },
  {
      value: "1417",
      name: "福建省农村信用社联合社"
  },
  {
      value: "1418",
      name: "北京农村商业银行"
  },
  {
      value: "1419",
      name: "天津农村商业银行股份有限公司"
  },
  {
      value: "（天津农村合作银行、天津农商银行）"
  },
  {
      value: "1420",
      name: "宁波鄞州农村合作银行"
  },
  {
      value: "1421",
      name: "佛山市三水区农村信用合作社联合社"
  },
  {
      value: "1422",
      name: "成都市农村信用合作社联合社"
  },
  {
      value: "1423",
      name: "沧州市农村信用合作社联合社"
  },
  {
      value: "1424",
      name: "江苏省农村信用合作社联合社"
  },
  {
      value: "1425",
      name: "江门市新会农村信用合作社联合社"
  },
  {
      value: "1426",
      name: "高要市农村信用合作社联合社"
  },
  {
      value: "1427",
      name: "佛山农村商业银行"
  },
  {
      value: "1428",
      name: "吴江农村商业银行"
  },
  {
      value: "1429",
      name: "浙江省农村信用社联合社"
  },
  {
      value: "1430",
      name: "江苏东吴农村商业银行"
  },
  {
      value: "1431",
      name: "珠海农商银行"
  },
  {
      value: "1432",
      name: "中山农村商业银行（中山农村信用合作社）"
  },
  {
      value: "1433",
      name: "太仓农村商业银行"
  },
  {
      value: "1434",
      name: "临汾市尧都市农村信用合作社联合社（山西尧都农村商业银行）"
  },
  // {
  //     value: "1435",
  //     name: "预留"
  // },
  {
      value: "1436",
      name: "贵州省农村信用社联合社"
  },
  {
      value: "1437",
      name: "无锡农村商业银行"
  },
  {
      value: "1438",
      name: "湖南省农村信用社联合社"
  },
  {
      value: "1439",
      name: "江西省农村信用社联合社"
  },
  {
      value: "1440",
      name: "甘肃省农村信用社联合社"
  },
  {
      value: "1441",
      name: "淄博市商业银行"
  },
  {
      value: "1442",
      name: "陕西省农村信用社联合社"
  },
  {
      value: "1501",
      name: "江苏银行"
  },
  {
      value: "1502",
      name: "邯郸市商业银行"
  },
  {
      value: "1503",
      name: "邢台银行"
  },
  {
      value: "1504",
      name: "承德银行"
  },
  {
      value: "1505",
      name: "沧州银行"
  },
  {
      value: "1506",
      name: "晋城市商业银行"
  },
  {
      value: "1507",
      name: "鄂尔多斯银行"
  },
  {
      value: "1508",
      name: "上饶银行"
  },
  {
      value: "1509",
      name: "东营市商业银行"
  },
  {
      value: "1510",
      name: "济宁银行"
  },
  {
      value: "1511",
      name: "泰安市商业银行"
  },
  {
      value: "1512",
      name: "德州银行"
  },
  {
      value: "1513",
      name: "开封市商业银行"
  },
  {
      value: "1514",
      name: "漯河市商业银行"
  },
  {
      value: "1515",
      name: "商丘市商业银行"
  },
  {
      value: "1516",
      name: "南阳市商业银行（南阳银行）"
  },
  {
      value: "1517",
      name: "浙江民泰商业银行"
  },
  {
      value: "1518",
      name: "龙江银行"
  },
  {
      value: "1519",
      name: "浙江稠州商业银行"
  },
  {
      value: "1520",
      name: "安徽省农村信用联社"
  },
  {
      value: "1521",
      name: "广西壮族自治区农村信用社联合社"
  },
  {
      value: "1522",
      name: "海南省农村信用社联合社"
  },
  {
      value: "1523",
      name: "云南省农村信用社联合社"
  },
  {
      value: "1524",
      name: "宁夏黄河农村商业银行"
  },
  {
      value: "1525",
      name: "安顺市商业银行"
  },
  {
      value: "1526",
      name: "安阳市商业银行"
  },
  {
      value: "1527",
      name: "保定市商业银行"
  },
  {
      value: "1528",
      name: "成都农村商业银行"
  },
  {
      value: "1529",
      name: "广东农村信用社"
  },
  {
      value: "1530",
      name: "河北省农村信用社联合社"
  },
  {
      value: "1531",
      name: "鹤壁银行"
  },
  {
      value: "1532",
      name: "衡水市商业银行"
  },
  {
      value: "1534",
      name: "晋中市商业银行"
  },
  {
      value: "1535",
      name: "库尔勒市商业银行"
  },
  {
      value: "1536",
      name: "乐山市商业银行"
  },
  {
      value: "1537",
      name: "凉山州商业银行"
  },
  {
      value: "1538",
      name: "内蒙古农村信用社"
  },
  {
      value: "1539",
      name: "山西省农村信用社"
  },
  {
      value: "1540",
      name: "深圳福田银座村镇银行"
  },
  {
      value: "1541",
      name: "遂宁市商业银行"
  },
  {
      value: "1542",
      name: "唐山市商业银行"
  },
  {
      value: "1543",
      name: "天津滨海农村商业银行"
  },
  {
      value: "1544",
      name: "乌海银行"
  },
  {
      value: "1545",
      name: "象山县绿叶城市信用社"
  },
  {
      value: "1546",
      name: "许昌银行"
  },
  {
      value: "1547",
      name: "雅安市商业银行"
  },
  {
      value: "1548",
      name: "阳泉市商业银行"
  },
  {
      value: "1549",
      name: "宜宾市商业银行"
  },
  {
      value: "1550",
      name: "云南红塔银行（玉溪市商业银行）"
  },
  {
      value: "1551",
      name: "周口市商业银行"
  },
  {
      value: "1552",
      name: "自贡市商业银行"
  },
  {
      value: "1553",
      name: "遵义市商业银行"
  },
  {
      value: "1554",
      name: "广东华兴银行"
  },
  {
      value: "1555",
      name: "长安银行"
  },
  {
      value: "1556",
      name: "北京顺义银座村镇银行"
  },
  {
      value: "1557",
      name: "浙江景宁银座村镇银行"
  },
  {
      value: "1558",
      name: "浙江三门银座村镇银行"
  },
  {
      value: "1559",
      name: "江西赣州银座村镇银行"
  },
  {
      value: "1560",
      name: "重庆渝北银座村镇银行"
  },
  {
      value: "1561",
      name: "重庆黔江银座村镇银行"
  },
  {
      value: "1562",
      name: "珲春农村商业银行"
  },
  {
      value: "1563",
      name: "浙江省农村信用社联合社"
  },
  {
      value: "1564",
      name: "山东省农村信用社联合社【废弃】"
  },
  {
      value: "1565",
      name: "颍淮农村商业银行"
  },
  // {
  //     value: "1566",
  //     name: "预留"
  // },
  {
      value: "1567",
      name: "山西长子农村商业银行"
  },
  // {
  //     value: "1568",
  //     name: "预留"
  // },
  {
      value: "1569",
      name: "贵州银行"
  },
  {
      value: "1570",
      name: "长治银行"
  },
  {
      value: "1571",
      name: "朝阳银行"
  },
  {
      value: "1572",
      name: "江苏江南农村商业银行"
  },
  {
      value: "1573",
      name: "宁波东海银行"
  },
  {
      value: "1574",
      name: "平顶山银行"
  },
  {
      value: "1575",
      name: "青海省农村信用社联合社"
  },
  {
      value: "1576",
      name: "四川省农村信用合作社"
  },
  {
      value: "1577",
      name: "铁岭商业银行"
  },
  {
      value: "1578",
      name: "武汉农村商业银行"
  },
  {
      value: "1579",
      name: "信阳银行"
  },
  {
      value: "1580",
      name: "延边农村商业银行"
  },
  {
      value: "1581",
      name: "西宁农村商业银行"
  },
  {
      value: "1582",
      name: "珲春农村商业银行（废弃）"
  },
  {
      value: "1583",
      name: "山西泽州农村商业银行"
  },
  {
      value: "1584",
      name: "黑龙江省农村信用社联合社"
  },
  {
      value: "1585",
      name: "新疆农村信用社"
  },
  {
      value: "1586",
      name: "江门融和农村商业银行"
  },
  {
      value: "1587",
      name: "台山市农村信用合作社"
  },
  {
      value: "1588",
      name: "鹤山市农村信用合作社"
  },
  {
      value: "1589",
      name: "贵阳农村商业银行"
  },
  {
      value: "1590",
      name: "清镇市农村信用合作社"
  },
  {
      value: "1591",
      name: "榕江县农村信用合作社"
  },
  {
      value: "1592",
      name: "安顺市农村商业银行"
  },
  {
      value: "1593",
      name: "宣威市农村信用合作社"
  },
  {
      value: "1594",
      name: "广东高明农村商业银行"
  },
  {
      value: "1595",
      name: "大理市农村合作银行"
  },
  {
      value: "1596",
      name: "水城县农村信用合作联社"
  },
  {
      value: "1597",
      name: "贵州惠水农村商业银行（惠水县农村信用合作联社）"
  },
  {
      value: "1598",
      name: "贵州织金农村商业银行"
  },
  {
      value: "1599",
      name: "贵州兴义农村商业银行"
  },
  {
      value: "1600",
      name: "贵州都匀农村商业银行"
  },
  {
      value: "1601",
      name: "梅县农村信用合作联社"
  },
  {
      value: "1602",
      name: "贵州福泉农村商业银行"
  },
  {
      value: "1603",
      name: "威宁县农村信用合作联社"
  },
  {
      value: "1604",
      name: "文山市农村信用合作联社"
  },
  {
      value: "1605",
      name: "开平市农村信用合作联社"
  },
  {
      value: "1606",
      name: "贵州湄潭农村商业银行"
  },
  {
      value: "1607",
      name: "三都县农村信用社（三都水族自治县农村信用合作联社）"
  },
  {
      value: "1608",
      name: "安顺市西秀区农村信用社联合社"
  },
  {
      value: "1609",
      name: "湖北十堰农村商业银行"
  },
  {
      value: "1610",
      name: "瓮安农村商业银行"
  },
  {
      value: "1611",
      name: "云南红塔农村合作银行"
  },
  {
      value: "1612",
      name: "遵义市汇川区农村信用合作联社"
  },
  {
      value: "1613",
      name: "广东河源农村商业银行"
  },
  {
      value: "1614",
      name: "贵州花溪农村商业银行"
  },
  {
      value: "1615",
      name: "天津金城银行"
  },
  {
      value: "1616",
      name: "中原银行"
  },
  {
      value: "1617",
      name: "洛阳市西工区农村信用合作联社"
  },
  {
      value: "1618",
      name: "洛阳市经贸开发区农村信用合作联社"
  },
  {
      value: "1619",
      name: "新安县农村信用合作社联合社"
  },
  {
      value: "1620",
      name: "郑州市市郊农村信用合作联社"
  },
  {
      value: "1621",
      name: "焦作市解放区农村信用合作社联合社"
  },
  {
      value: "1622",
      name: "封丘县农村信用合作社联合社"
  },
  {
      value: "1623",
      name: "河南济源农村商业银行（济源市农村信用合作联社）"
  },
  {
      value: "1624",
      name: "河南鄢陵农村商业银行（鄢陵县农村信用合作社联合社）"
  },
  {
      value: "1625",
      name: "衢州市柯城农村信用合作联社"
  },
  {
      value: "1626",
      name: "洛阳市市区农村信用合作社联合社"
  },
  {
      value: "1627",
      name: "河南辉县农村商业银行（辉县农村信用合作社联合社）"
  },
  {
      value: "1628",
      name: "山西平遥农村商业银行"
  },
  {
      value: "1629",
      name: "驻马店银行"
  },
  {
      value: "1630",
      name: "玉田县农村信用合作社联合社"
  },
  {
      value: "1631",
      name: "偃师市农村信用合作社联合社"
  },
  {
      value: "1632",
      name: "河南伊川农村商业银行"
  },
  {
      value: "1633",
      name: "林州市农村信用合作联社"
  },
  {
      value: "1634",
      name: "鄂尔多斯农村商业银行"
  },
  {
      value: "1635",
      name: "湖北沙洋农村商业银行"
  },
  {
      value: "1636",
      name: "长治漳泽农村商业银行"
  },
  {
      value: "1637",
      name: "肇庆端州农村商业银行"
  },
  {
      value: "1638",
      name: "青岛农村商业银行"
  },
  {
      value: "1639",
      name: "长治市郊区农村信用合作联社"
  },
  {
      value: "1640",
      name: "湖南浏阳农村商业银行"
  },
  {
      value: "1641",
      name: "湖南星沙农村商业银行"
  },
  {
      value: "1642",
      name: "湘潭农村商业银行"
  },
  {
      value: "1643",
      name: "威海农村商业银行"
  },
  {
      value: "1644",
      name: "大同北都农村商业银行"
  },
  {
      value: "1645",
      name: "长治潞州农村商业银行"
  },
  {
      value: "1646",
      name: "山西灵石农村商业银行"
  },
  {
      value: "1647",
      name: "山西五台农村商业银行"
  },
  {
      value: "1648",
      name: "山西乡宁农村商业银行"
  },
  {
      value: "1649",
      name: "山西寿阳农村商业银行"
  },
  {
      value: "1650",
      name: "山西侯马农村商业银行"
  },
  {
      value: "1651",
      name: "山西榆次农村商业银行"
  },
  {
      value: "1652",
      name: "山西盂县农村商业银行"
  },
  {
      value: "1653",
      name: "山西河津农村商业银行"
  },
  {
      value: "1654",
      name: "山西潞城农村商业银行"
  },
  {
      value: "1655",
      name: "山西古交农村商业银行"
  },
  {
      value: "1656",
      name: "山西运城农村商业银行"
  },
  {
      value: "1657",
      name: "山西清徐农村商业银行"
  },
  {
      value: "1658",
      name: "山西长治黎都农村商业银行"
  },
  {
      value: "1659",
      name: "山西忻州农村商业银行"
  },
  {
      value: "1660",
      name: "山西襄垣农村商业银行"
  },
  {
      value: "1661",
      name: "山西介休农村商业银行"
  },
  {
      value: "1662",
      name: "安徽天长农村商业银行"
  },
  {
      value: "1663",
      name: "沈阳农村商业银行"
  },
  {
      value: "1664",
      name: "浙江乐清农村商业银行"
  },
  {
      value: "1665",
      name: "浙江龙湾农村商业银行"
  },
  {
      value: "1666",
      name: "宁阳沪农商村镇银行"
  },
  {
      value: "1667",
      name: "湖南湘乡农村商业银行"
  },
  {
      value: "1668",
      name: "长沙雨花农村合作银行"
  },
  {
      value: "1669",
      name: "湘乡市村镇银行"
  },
  {
      value: "1670",
      name: "泰安泰山农村商业银行"
  },
  {
      value: "1671",
      name: "晋城农村商业银行"
  },
  {
      value: "1672",
      name: "河南省农村信用社"
  },
  {
      value: "1673",
      name: "南阳市农村信用社"
  },
  {
      value: "1674",
      name: "孟津民丰村镇银行"
  },
  {
      value: "1675",
      name: "栾川民丰村镇银行"
  },
  {
      value: "1676",
      name: "深圳南山宝生村镇银行"
  },
  {
      value: "1677",
      name: "吉林省农村信用社联合社"
  },
  {
      value: "1678",
      name: "吉林环城农村商业银行"
  },
  {
      value: "1679",
      name: "亳州药都农村商业银行"
  },
  {
      value: "1680",
      name: "辽宁东港农村商业银行"
  },
  {
      value: "1681",
      name: "东港市农村信用合作联社"
  },
  {
      value: "1682",
      name: "凤城农村商业银行"
  },
  {
      value: "1683",
      name: "沈阳农商银行"
  },
  {
      value: "1684",
      name: "辽宁省农村信用社"
  },
  {
      value: "1685",
      name: "抚顺县农村信用合作联社"
  },
  {
      value: "1686",
      name: "辽宁沈抚农村商业银行"
  },
  {
      value: "1687",
      name: "朝阳县农村信用合作联社"
  },
  {
      value: "1688",
      name: "建平县农村信用合作联社"
  },
  {
      value: "1689",
      name: "绥中县农村信用合作联社"
  },
  {
      value: "1690",
      name: "本溪满族自治县农村信用合作联社"
  },
  {
      value: "1691",
      name: "桓仁满族自治县农村信用联社"
  },
  {
      value: "1692",
      name: "浙江德清农村商业银行"
  },
  {
      value: "1693",
      name: "宁波通商银行"
  },
  {
      value: "1694",
      name: "枣庄银行"
  },
  {
      value: "1695",
      name: "海南银行"
  },
  {
      value: "1696",
      name: "西藏银行"
  },
  {
      value: "1697",
      name: "石嘴山银行"
  },
  {
      value: "1698",
      name: "哈密市商业银行"
  },
  {
      value: "1699",
      name: "新疆汇和银行"
  },
  {
      value: "1700",
      name: "海口联合农村商业银行"
  },
  {
      value: "1701",
      name: "东营莱商村镇银行"
  },
  {
      value: "1702",
      name: "广西上林国民村镇银行"
  },
  {
      value: "1703",
      name: "广西银海国民村镇银行"
  },
  {
      value: "1704",
      name: "广西钦州市钦南国民村镇银行"
  },
  {
      value: "1705",
      name: "广西浦北国民村镇银行"
  },
  {
      value: "1706",
      name: "防城港防城国民村镇银行"
  },
  {
      value: "1707",
      name: "东兴国民村镇银行"
  },
  {
      value: "1708",
      name: "新疆绿洲国民村镇银行"
  },
  {
      value: "1709",
      name: "克拉玛依金龙国民村镇银行"
  },
  {
      value: "1710",
      name: "哈密红星国民村镇银行"
  },
  {
      value: "1711",
      name: "昌吉国民村镇银行"
  },
  {
      value: "1712",
      name: "伊犁国民村镇银行"
  },
  {
      value: "1713",
      name: "奎屯国民村镇银行"
  },
  {
      value: "1714",
      name: "石河子国民村镇银行"
  },
  {
      value: "1715",
      name: "上海华瑞银行"
  },
  {
      value: "1716",
      name: "浙江网商银行"
  },
  {
      value: "1717",
      name: "深圳前海微众银行"
  },
  {
      value: "1718",
      name: "山西长治壶关农村商业银行"
  },
  {
      value: "1719",
      name: "山西大同市大同县农村信用合作联社"
  },
  {
      value: "1720",
      name: "山西晋中市榆社县农村信用合作联社"
  },
  {
      value: "1721",
      name: "山西长治市黎城县农村信用合作联社"
  },
  {
      value: "1722",
      name: "山西长治市平顺县农村信用合作联社"
  },
  {
      value: "1723",
      name: "山西长治市沁县农村信用合作联社"
  },
  {
      value: "1724",
      name: "山西长治市沁源县农村信用合作联社"
  },
  {
      value: "1725",
      name: "山西长治市屯留县农村信用合作联社"
  },
  {
      value: "1726",
      name: "山西长治市武乡县农村信用合作联社"
  },
  {
      value: "1727",
      name: "太原市城区农村信用合作联社"
  },
  {
      value: "1728",
      name: "敦化农村商业银行"
  },
  {
      value: "1729",
      name: "安徽东至农村商业银行"
  },
  {
      value: "1730",
      name: "朗溪新华村镇银行"
  },
  {
      value: "1731",
      name: "重庆富民银行"
  },
  {
      value: "1732",
      name: "浙江新昌农村商业银行"
  },
  {
      value: "1733",
      name: "扬州农村商业银行"
  },
  {
      value: "1734",
      name: "石家庄汇融农村合作银行"
  },
  {
      value: "1735",
      name: "潍坊农村商业银行"
  },
  {
      value: "1736",
      name: "江苏射阳农村商业银行"
  },
  {
      value: "1737",
      name: "宜兴农村商业银行"
  },
  {
      value: "1738",
      name: "象山国民村镇银行"
  },
  {
      value: "1739",
      name: "象山县农村信用合作联社"
  },
  {
      value: "1740",
      name: "贵州兴仁农村商业银行"
  },
  {
      value: "1741",
      name: "浙江义乌农村商业银行股份有限公司"
  },
  {
      value: "1742",
      name: "浙江永康农村商业银行股份有限公司"
  },
  {
      value: "1743",
      name: "瓯海农村商业银行股份有限公司"
  },
  {
      value: "1744",
      name: "杭州联合银行"
  },
  {
      value: "1745",
      name: "浙江瑞安农村商业银行股份有限公司"
  },
  {
      value: "1746",
      name: "宁波市北仑区农村信用合作联社"
  },
  {
      value: "1747",
      name: "四川新网银行股份有限公司"
  },
  {
      value: "1748",
      name: "达州银行股份有限公司"
  },
  {
      value: "1749",
      name: "武汉众邦银行"
  },
  {
      value: "1750",
      name: "紫金农商银行"
  },
  {
      value: "1751",
      name: "吉林九台农商银行"
  },
  {
      value: "1752",
      name: "浦江农村商业银行"
  },
  {
      value: "1753",
      name: "湖北来凤农村商业银行"
  },
  {
      value: "1754",
      name: "宁波余姚农村商业银行"
  },
  {
      value: "1755",
      name: "佛冈县农村信用合作联社"
  },
  {
      value: "1756",
      name: "贵州乌当农村商业银行"
  },
  {
      value: "1757",
      name: "科尔沁左翼后旗农村信用合作联社博王信用社"
  },
  {
      value: "1758",
      name: "湖南攸县农村商业银行股份有限公司"
  },
  {
      value: "1759",
      name: "内蒙古土默特右旗农村商业银行"
  },
  {
      value: "1760",
      name: "永济三禾村镇银行"
  },
  {
      value: "1761",
      name: "禾城农商银行"
  },
  {
      value: "1762",
      name: "文水县农村信用合作联社"
  },
  {
      value: "1763",
      name: "长沙农商银行"
  },
  {
      value: "1764",
      name: "江苏大丰农村商业银行"
  },
  {
      value: "1765",
      name: "贵阳小河科技村镇银行"
  },
  {
      value: "1766",
      name: "梅州客商银行"
  },
  {
      value: "1767",
      name: "苏宁银行"
  },
  {
      value: "1768",
      name: "余杭农村商业银行"
  },
  {
      value: "1769",
      name: "平阳农村商业银行"
  },
  {
      value: "1770",
      name: "山西阳曲农村商业银行"
  },
  {
      value: "1771",
      name: "蓝海银行"
  },
  {
      value: "1772",
      name: "江苏南通农村商业银行"
  },
  {
      value: "1773",
      name: "泸州纳溪农村信用合作联社"
  },
  {
      value: "1774",
      name: "中信百信银行"
  },
  {
      value: "1775",
      name: "安阳商都农商银行"
  },
  {
      value: "1776",
      name: "内江兴隆村镇银行"
  },
  {
      value: "1777",
      name: "大连农村商业银行股份有限公司"
  },
  {
      value: "1778",
      name: "浙江萧山湖商村镇银行股份有限公司"
  },
  {
      value: "1779",
      name: "荥阳利丰村镇银行股份有限公司"
  },
  {
      value: "1780",
      name: "应县金都村镇银行有限责任公司"
  },
  {
      value: "1781",
      name: "阳高县兴都村镇银行有限责任公司"
  },
  {
      value: "1782",
      name: "襄汾县万都村镇银行有限责任公司"
  },
  {
      value: "1783",
      name: "忻州市忻府区秀都村镇银行有限责任公司"
  },
  {
      value: "1784",
      name: "武乡县泽都村镇银行有限责任公司"
  },
  {
      value: "1785",
      name: "文水县润都村镇银行有限责任公司"
  },
  {
      value: "1786",
      name: "太原市尖草坪区信都村镇银行股份有限公司"
  },
  {
      value: "1787",
      name: "长治县泰都村镇银行有限责任公司"
  },
  {
      value: "1788",
      name: "寿阳县汇都村镇银行有限责任公司"
  },
  {
      value: "1789",
      name: "平定县昌都村镇银行有限责任公司"
  },
  {
      value: "1790",
      name: "宁武县瑞都村镇银行有限责任公司"
  },
  {
      value: "1791",
      name: "临县泉都村镇银行有限责任公司"
  },
  {
      value: "1792",
      name: "介休市华都村镇银行有限责任公司"
  },
  {
      value: "1793",
      name: "交口县融都村镇银行有限责任公司"
  },
  {
      value: "1794",
      name: "大同市南郊区京都村镇有限责任公司"
  },
  {
      value: "1795",
      name: "洪洞县洪都村镇银行有限责任公司"
  },
  {
      value: "1796",
      name: "汾阳市九都村镇银行有限责任公司"
  },
  {
      value: "1797",
      name: "代县泓都村镇银行有限责任公司"
  },
  {
      value: "1798",
      name: "和顺县贵都村镇银行有限责任公司"
  },
  {
      value: "1799",
      name: "河津市龙都村镇银行有限责任公司"
  },
  {
      value: "1800",
      name: "长春和润村镇银行有限责任公司"
  },
  {
      value: "1801",
      name: "中银富登村镇银行有限责任公司"
  },
  {
      value: "1802",
      name: "山东青隆村镇银行有限责任公司"
  },
  {
      value: "1803",
      name: "宁夏原州村镇银行有限责任公司"
  },
  {
      value: "1804",
      name: "四川天府银行股份有限公司"
  },
  {
      value: "1805",
      name: "湖南三湘银行股份有限公司"
  },
  {
      value: "1806",
      name: "呼和浩特金谷农村商业银行股份有限公司"
  },
  {
      value: "1807",
      name: "丰城顺银村镇银行"
  },
  {
      value: "1808",
      name: "泰州农村商业银行"
  },
  {
      value: "1809",
      name: "吉林公主岭农村商业银行"
  },
  {
      value: "1810",
      name: "烟台农商银行"
  },
  {
      value: "1811",
      name: "长春农村商业发展银行"
  },
  {
      value: "1812",
      name: "江苏高淳农村商业银行"
  },
  {
      value: "1813",
      name: "广西宜州农村信用合作银行"
  },
  {
      value: "1814",
      name: "富邦银行"
  },
  {
      value: "1815",
      name: "浙江海宁农商行"
  },
  {
      value: "1816",
      name: "南昌农商行"
  },
  {
      value: "1817",
      name: "温州瓯海农商行（废弃）"
  },
  {
      value: "1818",
      name: "杭州义乌农商行"
  },
  {
      value: "1819",
      name: "杭州安吉农商行"
  },
  {
      value: "1820",
      name: "杭州桐乡农商行"
  },
  {
      value: "1821",
      name: "营口沿海银行股份有限公司"
  },
  {
      value: "1822",
      name: "宁波市海曙国民村镇银行有限责任公司"
  },
  {
      value: "1823",
      name: "合浦国民村镇银行有限责任公司"
  },
  {
      value: "1824",
      name: "平果国民村镇银行有限责任公司"
  },
  {
      value: "1825",
      name: "北屯国民村镇银行有限责任公司"
  },
  {
      value: "1826",
      name: "五家渠国民村镇银行有限责任公司"
  },
  {
      value: "1827",
      name: "博乐国民村镇银行有限责任公司"
  },
  {
      value: "1828",
      name: "温州民商银行股份有限公司"
  },
  {
      value: "1829",
      name: "厦门农村商业银行股份有限公司"
  },
  {
      value: "1830",
      name: "昆明官渡农村合作银行"
  },
  {
      value: "1831",
      name: "启东农村商业银行"
  },
  {
      value: "1832",
      name: "吉林亿联银行股份有限公司"
  },
  {
      value: "1833",
      name: "陕西秦农农村商业银行股份有限公司（秦农银行）"
  },
  {
      value: "1834",
      name: "山西左云农村商业银行"
  },
  {
      value: "1835",
      name: "新郑郑银村镇银行"
  },
  {
      value: "1836",
      name: "浙江衢州衢江农村商业银行"
  },
  {
      value: "1837",
      name: "福建华通银行"
  },
  {
      value: "1838",
      name: "海口农商银行"
  },
  {
      value: "1839",
      name: "辽宁振兴银行"
  },
  {
      value: "1840",
      name: "新安银行"
  },
  {
      value: "1841",
      name: "抚州农村商业银行"
  },
  {
      value: "1842",
      name: "山西孝义农村商业银行"
  },
  {
      value: "1843",
      name: "河南新县农村商业银行"
  },
  {
      value: "1844",
      name: "山西河曲农村商业银行"
  },
  {
      value: "1845",
      name: "泸州龙马潭农村商业银行"
  },
  {
      value: "1846",
      name: "北京中关村银行"
  },
  {
      value: "1847",
      name: "河南内乡农村商业银行"
  },
  {
      value: "1848",
      name: "江苏姜堰农村商业银行"
  },
  {
      value: "1849",
      name: "浙江嵊州农村商业银行"
  },
  {
      value: "1850",
      name: "浙江上虞农村商业银行"
  },
  {
      value: "1851",
      name: "桂林国民村镇银行"
  },
  {
      value: "1852",
      name: "山东新泰农村商业银行"
  },
  {
      value: "1853",
      name: "代县农村信用合作联社"
  },
  {
      value: "1854",
      name: "安徽桐城农村商业银行"
  },
  {
      value: "1855",
      name: "广元市贵商村镇银行"
  },
  {
      value: "1856",
      name: "阜阳颖东农村商业银行"
  },
  {
      value: "1857",
      name: "长春农村商业银行股份有限公司"
  },
  {
      value: "1858",
      name: "湖北长阳农村商业银行股份有限公司"
  },
  {
      value: "1859",
      name: "浙江玉环农村商业银行股份有限公司"
  },
  {
      value: "1860",
      name: "山东临淄农村商业银行股份有限公司"
  },
  {
      value: "1861",
      name: "黄山太平农村商业银行股份有限公司"
  },
  {
      value: "1862",
      name: "惠州农村商业银行股份有限公司"
  },
  {
      value: "1863",
      name: "江苏泰兴农村商业银行股份有限公司"
  },
  {
      value: "1864",
      name: "南宁市区农村信用合作联社"
  },
  {
      value: "1865",
      name: "韶关市农村信用合作社联合社"
  },
  {
      value: "1866",
      name: "张家界农村商业银行股份有限公司"
  },
  {
      value: "1867",
      name: "浙江岱山农村商业银行股份有限公司"
  },
  {
      value: "1868",
      name: "广西桂林漓江农村合作银行"
  },
  {
      value: "1869",
      name: "江苏高邮农村商业银行股份有限公司"
  },
  {
      value: "1870",
      name: "丽江古城富滇村镇银行"
  },
  {
      value: "1871",
      name: "雅安雨城惠民村镇银行有限责任公司"
  },
  {
      value: "1872",
      name: "安徽黟县农村商业银行股份有限公司"
  },
  {
      value: "1873",
      name: "浙江海盐农村商业银行股份有限公司"
  },
  {
      value: "1874",
      name: "青岛黄岛舜丰村镇银行"
  },
  {
      value: "1875",
      name: "浙江长兴农村商业银行股份有限公司"
  },
  {
      value: "1876",
      name: "新疆昌吉农村商业银行"
  },
  {
      value: "1877",
      name: "深圳坪山珠江村镇银行股份有限公司"
  },
  {
      value: "1878",
      name: "永丰银行（中国）有限公司"
  },
  {
      value: "1879",
      name: "深圳宝安融兴村镇银行"
  },
  {
      value: "1880",
      name: "陵水黎族自治县农村信用合作联社"
  },
  {
      value: "1881",
      name: "巴中农村商业银行"
  },
  {
      value: "1882",
      name: "信阳平桥中原村镇银行股份有限公司"
  },
  {
      value: "1883",
      name: "西昌金信村镇银行"
  },
  {
      value: "1884",
      name: "贵阳观山湖富民村镇银行"
  },
  {
      value: "1885",
      name: "哈尔滨农村商业银行"
  },
  {
      value: "1886",
      name: "大丰银行有限公司"
  },
  {
      value: "1887",
      name: "安顺市平坝区农村信用合作联社"
  },
  {
      value: "1888",
      name: "四川银行股份有限公司"
  },
  {
      value: "1889",
      name: "江西裕民银行股份有限公司"
  },
  {
      value: "1890",
      name: "河南新郑农村商业银行"
  },
  {
      value: "1891",
      name: "开封新东方村镇银行股份有限公司"
  },
  {
      value: "1892",
      name: "兰考齐鲁村镇银行有限责任公司"
  },
  {
      value: "1893",
      name: "聊城沪农商村镇银行股份有限公司"
  },
  {
      value: "1894",
      name: "沈丘中银富登村镇银行有限公司"
  },
  {
      value: "1895",
      name: "无锡锡商银行股份有限公司"
  },
  {
      value: "1896",
      name: "浦发硅谷银行有限公司"
  },
  {
      value: "1897",
      name: "江苏丰县民丰村镇银行"
  },
  {
      value: "3000",
      name: "汇丰银行"
  },
  {
      value: "3001",
      name: "东亚银行"
  },
  {
      value: "3002",
      name: "南洋商业银行"
  },
  {
      value: "3003",
      name: "恒生银行(中国)有限公司"
  },
  {
      value: "3004",
      name: "中国银行（香港）有限公司"
  },
  {
      value: "3005",
      name: "集友银行有限公司"
  },
  {
      value: "3006",
      name: "创兴银行有限公司"
  },
  {
      value: "3007",
      name: "星展银行（中国）有限公司"
  },
  {
      value: "3008",
      name: "永亨银行（中国）有限公司"
  },
  {
      value: "3009",
      name: "永隆银行"
  },
  {
      value: "3010",
      name: "花旗银行（中国）有限公司"
  },
  {
      value: "3011",
      name: "美国银行有限公司"
  },
  {
      value: "3012",
      name: "摩根大通银行(中国)有限公司"
  },
  {
      value: "3013",
      name: "三菱东京日联银行(中国）有限公司"
  },
  {
      value: "3014",
      name: "日本三井住友银行股份有限公司"
  },
  {
      value: "3015",
      name: "瑞穗实业银行（中国）有限公司"
  },
  {
      value: "3016",
      name: "日本山口银行股份有限公司"
  },
  {
      value: "3017",
      name: "外换银行（中国）有限公司"
  },
  {
      value: "3018",
      name: "友利银行(中国)有限公司"
  },
  {
      value: "3021",
      name: "韩国产业银行"
  },
  {
      value: "3022",
      name: "新韩银行(中国)有限公司"
  },
  {
      value: "3023",
      name: "韩国中小企业银行"
  },
  {
      value: "3024",
      name: "韩亚银行（中国）有限公司"
  },
  {
      value: "3025",
      name: "华侨银行（中国）有限公司"
  },
  {
      value: "3026",
      name: "大华银行（中国）有限公司"
  },
  {
      value: "3028",
      name: "泰国盘谷银行(大众有限公司)"
  },
  {
      value: "3029",
      name: "奥地利中央合作银行股份有限公司"
  },
  {
      value: "3030",
      name: "比利时联合银行股份有限公司"
  },
  {
      value: "3031",
      name: "比利时富通银行有限公司"
  },
  {
      value: "3032",
      name: "荷兰银行"
  },
  {
      value: "3033",
      name: "荷兰安智银行股份有限公司"
  },
  {
      value: "3034",
      name: "渣打银行"
  },
  {
      value: "3035",
      name: "英国苏格兰皇家银行公众有限公司"
  },
  {
      value: "3036",
      name: "法国兴业银行（中国)有限公司"
  },
  {
      value: "3037",
      name: "法国东方汇理银行股份有限公司"
  },
  {
      value: "3038",
      name: "法国外贸银行股份有限公司"
  },
  {
      value: "3039",
      name: "德国德累斯登银行股份公司"
  },
  {
      value: "3040",
      name: "德意志银行（中国）有限公司"
  },
  {
      value: "3041",
      name: "德国商业银行股份有限公司"
  },
  {
      value: "3042",
      name: "德国西德银行股份有限公司"
  },
  {
      value: "3043",
      name: "德国巴伐利亚州银行"
  },
  {
      value: "3044",
      name: "德国北德意志州银行"
  },
  {
      value: "3045",
      name: "意大利联合圣保罗银行股份有限公司"
  },
  {
      value: "3046",
      name: "瑞士信贷银行股份有限公司"
  },
  {
      value: "3047",
      name: "瑞士银行"
  },
  {
      value: "3048",
      name: "加拿大丰业银行有限公司"
  },
  {
      value: "3049",
      name: "加拿大蒙特利尔银行有限公司"
  },
  {
      value: "3050",
      name: "澳大利亚和新西兰银行集团有限公司"
  },
  {
      value: "3051",
      name: "摩根士丹利国际银行（中国）有限公司"
  },
  {
      value: "3052",
      name: "联合银行(中国)有限公司"
  },
  {
      value: "3053",
      name: "荷兰合作银行有限公司"
  },
  {
      value: "3054",
      name: "厦门国际银行"
  },
  {
      value: "3055",
      name: "法国巴黎银行（中国）有限公司"
  },
  {
      value: "3056",
      name: "华商银行"
  },
  {
      value: "3057",
      name: "华一银行"
  },
  {
      value: "3058",
      name: "中信银行国际（中国）有限公司"
  }
];

export const personalBankList = [
    {
        label: "邮储银行",
        value: "100"
    },
    {
        label: "工商银行",
        value: "102"
    },
    {
        label: "农业银行",
        value: "103"
    },
    {
        label: "中国银行",
        value: "104"
    },
    {
        label: "建设银行",
        value: "105"
    },
    {
        label: "交通银行",
        value: "301"
    },
    {
        label: "中信银行",
        value: "302"
    },
    {
        label: "光大银行",
        value: "303"
    },
    {
        label: "华夏银行",
        value: "304"
    },
    {
        label: "民生银行",
        value: "305"
    },
    {
        label: "平安银行",
        value: "307"
    },
    {
        label: "招商银行",
        value: "308"
    },
    {
        label: "兴业银行",
        value: "309"
    },
    {
        label: "浦发银行",
        value: "310"
    },
    {
        label: "浙商银行",
        value: "316"
    },
    {
        label: "上海银行",
        value: "401"
    },
    {
        label: "北京银行",
        value: "403"
    },
    {
        label: "宁波银行",
        value: "408"
    },
    {
        label: "齐鲁银行",
        value: "409"
    },
    {
        label: "杭州银行",
        value: "423"
    },
    {
        label: "南京银行",
        value: "424"
    },
    {
        label: "天津银行",
        value: "434"
    },
    {
        label: "徽商银行",
        value: "440"
    },
    {
        label: "青岛银行",
        value: "450"
    },
    {
        label: "贵州银行",
        value: "1569"
    },
    {
        label: "成都银行",
        value: "429"
    }
];

export const companyBankList = [
    {
        label: "邮储银行",
        value: "100"
    },
    {
        label: "工商银行",
        value: "102"
    },
    {
        label: "农业银行",
        value: "103"
    },
    {
        label: "中国银行",
        value: "104"
    },
    {
        label: "建设银行",
        value: "105"
    },
    {
        label: "交通银行",
        value: "301"
    },
    {
        label: "中信银行",
        value: "302"
    },
    {
        label: "光大银行",
        value: "303"
    },
    {
        label: "华夏银行",
        value: "304"
    },
    {
        label: "民生银行",
        value: "305"
    },
    {
        label: "广发银行",
        value: "306"
    },
    {
        label: "平安银行",
        value: "307"
    },
    {
        label: "招商银行",
        value: "308"
    },
    {
        label: "兴业银行",
        value: "309"
    },
    {
        label: "浦发银行",
        value: "310"
    },
    {
        label: "上海银行",
        value: "401"
    },
    {
        label: "北京银行",
        value: "403"
    },
    {
        label: "烟台银行",
        value: "404"
    },
    {
        label: "宁波银行",
        value: "408"
    },
    {
        label: "齐鲁银行",
        value: "409"
    },
    {
        label: "杭州银行",
        value: "423"
    },
    {
        label: "天津银行",
        value: "434"
    },
    {
        label: "徽商银行",
        value: "440"
    },
    {
        label: "青岛银行",
        value: "450"
    },
    {
        label: "贵州银行",
        value: "1569"
    },
    {
        label: "浙商银行",
        value: "316"
    },
    {
        label: "厦门银行",
        value: "402"
    },
    {
        label: "莱商银行",
        value: "497"
    },
    {
        label: "日照银行",
        value: "455"
    }
];
